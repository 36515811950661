<template>
  <div>
    <div class='flex-sb'>
      <div class='flex-sb'>
        <h3>当前预览：Excel（{{ fileInfo.name || '--' }}）</h3>
        <span>共 <span style='color: #fe346e'>{{ columns.length }}</span> 列</span>
      </div>
      <div class='flex-end'>
        <el-button @click='prevPage'><span class='el-icon-arrow-left'></span>上一页</el-button>
        <div style='line-height: 20px'>
          <span>第 {{ pagingData.current_page }} 页</span>
          <span> &nbsp;&nbsp;每页：{{ pagingData.page_size }} 条</span>
        </div>
        <el-button @click='nextPage'>下一页<span class='el-icon-arrow-right'></span></el-button>
        <div style='display: flex;flex-direction: row;align-items: center;justify-content: space-evenly;gap: 4px'>
          前往
          <el-input class='page-jumper' style='width: 50px;text-align: center' v-model='pagingData.current_page'
                    placeholder='前往指定页'
                    @change='getList'></el-input>
          页
        </div>
      </div>
    </div>

    <div class='default-table'
         v-loading='loading'
         element-loading-text='加载中……'
         element-loading-spinner='el-icon-loading'>
      <el-table
        :data='dataList'
        highlight-current-row
        border>
        <el-table-column type='index' label='序号'></el-table-column>
        <template v-for='(v,k) in columns'>
          <template v-if='!v.hide&&!v.slot'>
            <el-table-column
              :column-key='v.prop'
              :key='k'
              :prop="v.prop?v.prop:typeof(v.field)=='string'?v.prop:''"
              :label='v.label'
              :width='v.width'
              :show-overflow-tooltip='v?.overflow||true'
              :header-align="v.header_align?v.header_align:'center'"
              :align="v.align?v.align:'center'"
              :fixed='v.fixed'
              :sortable='v.sortable?v.sortable:false'>
              <template slot-scope='{row,column}'>
                <div v-if='!v.editable'>
                  <div v-if="typeof(v.prop)=='string'">
                    <div v-if="v.type === 'image'">
                      <el-image
                        :src='row[column.property]' :alt='row[column.property]' style='width: 100%;height: 100%'
                        width='40px' fit='scale-down'
                        :preview-src-list='[row[column.property]]'>
                        <div slot='error' class='image-slot'>
                          暂无图片
                        </div>
                      </el-image>
                    </div>
                    <div v-else-if="v.type === 'images'&&Array.isArray(row[column.property])" style='display: flex;'>
                      <el-image
                        v-for='(image,image_index) in row[column.property]' :key='image_index+"image"'
                        :src='image.src' width='40px' fit='scale-down'
                        :preview-src-list='row[column.property].map(_=>{return _.src})' />
                    </div>
                    <span v-else-if="typeof(v.field)=='string'">
                      {{ row[v.field] || '' }}
                    </span>
                    <span v-else-if='v.icon'>
                       {{ row[column.property] }}
                       <i :title="v.editDialog?'双击修改':row[column.property]"
                          :style="v.editDialog?{cursor: 'pointer'}:''" :class='v.icon'></i>
                    </span>
                    <span v-else v-html='row[column.property]'></span>
                  </div>
                </div>
              </template>

            </el-table-column>
          </template>
          <template v-else-if='!v.hide&&v.slot'>
            <slot :name='v.prop' />
          </template>
        </template>
      </el-table>
    </div>

    <div class='flex-sb'>
      <div class='flex-sb'>
        <h3>当前预览：Excel（{{ fileInfo.name || '--' }}）</h3>
        <span>共 <span style='color: #fe346e'>{{ columns.length }}</span> 列</span>
      </div>
      <div class='flex-end'>
        <el-button @click='prevPage'><span class='el-icon-arrow-left'></span>上一页</el-button>
        <div style='line-height: 20px'>
          <span>第 {{ pagingData.current_page }} 页</span>
          <span> &nbsp;&nbsp;每页：{{ pagingData.page_size }} 条</span>
        </div>
        <el-button @click='nextPage'>下一页<span class='el-icon-arrow-right'></span></el-button>
        <div style='display: flex;flex-direction: row;align-items: center;justify-content: space-evenly;gap: 4px'>
          前往
          <el-input class='page-jumper' style='width: 50px;text-align: center' v-model='pagingData.current_page'
                    placeholder='前往指定页'
                    @change='getList'></el-input>
          页
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { readTradeFile } from '@/api/trade_import'

export default {
  name: 'ExcelPageTable',
  props: {
    fileInfo: {
      type: Object,
      default() {
        return {
          name: '--'
        }
      }
    }
  },
  data() {
    return {
      tableName: 'trade_excel',
      loading: false,
      pagingData: { current_page: 1, page_size: 20, total: 10000 },
      dataList: [],
      columns: [
        // { hide: false, prop: 'idx', label: '序号', width: 60, sortable: false, fixed: false },
        // { hide: false, prop: 'tid', label: '订单ID', width: 100, sortable: false, fixed: false },
        // { hide: false, prop: 'goods_id', label: '商品ID', width: 100, sortable: false, fixed: false },
        // { hide: false, prop: 'goods_title', label: '商品名称', width: 100, sortable: false, fixed: false },
        // { hide: false, prop: 'pay_at', label: '付款时间', width: 100, sortable: false, fixed: false },
        // { hide: false, prop: 'kol_nickname', label: '达人昵称', width: 100, sortable: false, fixed: false },
        // { hide: false, prop: 'kol_id', label: '达人抖音号', width: 100, sortable: false, fixed: false },
        // { hide: false, prop: 'trade_status', label: '订单状态', width: 100, sortable: false, fixed: false },
        // { hide: false, prop: 'trade_source', label: '下单来源', width: 100, sortable: false, fixed: false },
        // { hide: false, prop: 'flow_source', label: '流量来源', width: 100, sortable: false, fixed: false },
        // { hide: false, prop: 'flow_sub', label: '流量细分渠道', width: 100, sortable: false, fixed: false },
        // { hide: false, prop: 'file_md5', label: '导入文件md5', width: 100, sortable: false, fixed: false }
      ]
    }
  },
  methods: {
    init() {
      this.handleQuery()
    },
    handleQuery() {
      this.pagingData.current_page = 1
      this.getList()
    },

    /**
     * 选中文件装载到《订单表》
     * @returns {Promise<void>}
     */
    async showExcelData() {
      this.loading = true
      const fileId = this.fileInfo.id
      let params = { file_upload_id: fileId }
      Object.assign(params, { current_page: this.pagingData.current_page, page_size: this.pagingData.page_size })
      let { list } = await readTradeFile(params)
      if (params.current_page === 1)
        await this.show(list)
      else {
        this.dataList = list || []
      }
      this.loading = false
    },

    async show(list) {
      if (list && list[0] && list[0][0] === '行号') {
        // 列行
        let cols = list.shift()
        await this.calcColumns(cols)
      }
      setTimeout(() => {
        this.calcTableList(list)
      }, 1)
    },
    calcColumns(cols) {
      let colsSet = []
      // let colsArrStr = ''
      cols.forEach((colName, index) => {
        colsSet.push({
          hide: false,
          prop: `${index}`,
          label: colName,
          width: 120,
          sortable: false,
          fixed: false
        })
        // colsArrStr += ` ['label' => '${colName}', 'prop' => '${colName}'],`
      })

      // console.log(colsArrStr)

      this.columns = colsSet

    },
    calcTableList(list) {
      let rows = []
      list.forEach((item) => {
        let row = []
        this.columns.forEach((col, index) => {
          row[Number(col.prop)] = item[index]
        })
        rows.push(row)

      })

      this.dataList = rows
      return rows
    },
    getList() {
      if (Number(this.pagingData.current_page) < 1) {
        this.pagingData.current_page = 1
      }
      this.showExcelData()
      // this.$emit('getList', this.pagingData)
    },
    prevPage() {
      this.pagingData.current_page -= 1
      this.getList()
    },
    nextPage() {
      this.pagingData.current_page += 1
      this.getList()
    }
  }
}
</script>

<style scoped>
.excel-table {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #ddd;
  padding: 8px;
}

.excel-table .row {
  line-height: 40px;
  height: 40px;
  width: 100%;
  box-sizing: border-box;
  overflow-x: scroll;
}

.excel-table span.cell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-all;
  line-height: 23px;
  padding-left: 10px;
  padding-right: 10px;
}

/*.page-jumper::before {*/
/*  content: '前往'*/
/*}*/

/*.page-jumper::after {*/
/*  content: '页'*/
/*}*/
</style>
