import SparkMD5 from 'spark-md5'
// 将axios换成封装好的axios
// import request from '@/utils/request'

import { Message } from 'element-ui'
import { fileChunkMerge, fileChunkRegister, fileChunkUpload } from '@/api/fileUpload'

// 分片上传的自定义请求，以下请求会覆盖element的默认上传行为
export default function upload(option) {
  if (typeof XMLHttpRequest === 'undefined') {
    return
  }
  // 首次进来并没有选择文件，因此直接return
  if (option.file === undefined) {
    return
  }

  //文件分割方法-兼容
  let blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
  const spark = new SparkMD5.ArrayBuffer()// md5的ArrayBuffer加密类
  const fileReader = new FileReader()// 文件读取类
  // const action = option.action // 文件上传上传路径
  const chunkSize = 1024 * 1024 * 5// 单个分片大小
  let chunkContinuesNum = 0
  let chunkOffset = 0 // 当前分片的偏移值
  let md5 = '' // 文件的唯一标识
  const optionFile = option.file // 需要分片的文件
  let fileChunkedList = [] // 文件分片完成之后的数组
  const percentage = [] // 文件上传进度的数组，单项就是一个分片的进度
  let currentChunk = 0
  const chunks = Math.ceil(optionFile.size / chunkSize)

  //[计算MD5值]最耗费时间的[官方案例：https://unpkg.com/browse/spark-md5@3.0.2/README.md]
  function loadNext() {
    let start = currentChunk * chunkSize,
      end = ((start + chunkSize) >= optionFile.size) ? optionFile.size : start + chunkSize
    // const blob = optionFile.slice(start, end);
    const blob = blobSlice.call(optionFile, start, end)
    fileReader.readAsArrayBuffer(blob)
    fileChunkedList.push(blob)
  }

  // 在文件读取完毕之后，开始计算文件md5，作为文件唯一标识
  fileReader.onload = async (e) => {
    // console.log('read chunk nr', currentChunk + 1, 'of', chunks)
    //【解析进度】 进度条改为绿色
    e.percent = 100 * (currentChunk + 1) / chunks
    option.onProgress(e)
    spark.append(e.target.result)   // Append array buffer

    currentChunk++
    if (currentChunk < chunks) {
      loadNext()
    } else {
      Message({
        type: 'warning',
        message: '文件解析完毕，开始上传...'
      })
      // console.log('finished loading')
      md5 = spark.end(false)
      // console.info('computed hash', md5)  // Compute hash
      // 打印文件hash计算时间
      // console.timeEnd('loadNext')

      // 【请求数据格式化】将fileChunkedList转成FormData对象，并加入上传时需要的数据
      fileChunkedList = fileChunkedList.map((item, index) => {
        // eslint-disable-next-line no-console
        const formData = new FormData()
        if (option.data) {
          // 额外加入外面传入的data数据
          Object.keys(option.data).forEach(key => {
            formData.append(key, option.data[key])
          })
          // 这些字段看后端需要哪些，就传哪些，也可以自己追加额外参数
          formData.append('chunk_file', item, option.file.name)// 文件
          formData.append('chunk_num', index + 1)// 当前文件块
          formData.append('chunk_size', chunkSize)// 单个分块大小
          // formData.append('currentChunkSize', item.size)// 当前分块大小
          if (index + 1 === chunkContinuesNum) {
            formData.append('offset', chunkOffset)// 当前分块偏移值
          }
          formData.append('file_size', optionFile.size)// 文件总大小
          formData.append('file_md5', md5)// 文件标识
          formData.append('total_chunks', fileChunkedList.length)// 总块数
        }

        return { formData: formData, index: index }
      })
      // 更新上传进度条百分比的方法
      const updatePercentage = (e) => {
        let loaded = 0// 当前已经上传文件的总大小
        percentage.forEach(item => {
          loaded += item
        })
        e.percent = loaded / optionFile.size * 100
        option.onProgress(e)
      }

      // 创建队列上传任务，limit是上传并发数
      const sendRequest = (chunks, limit = 1) => {
        return new Promise((resolve, reject) => {
          const len = chunks.length
          let counter = 0
          let isStop = false
          const start = async () => {
            if (isStop) {
              return
            }
            // 分片数组弹出头个元素
            const item = chunks.shift()
            if (item) {
              // 断点续传，跳过已上传的分片
              if (counter + 1 >= chunkContinuesNum) {

                // 给后端发送文件合并请求
                const chunkUpload = await fileChunkUpload(item.formData)
                if (chunkUpload.code !== 0) {
                  Message({
                    type: 'error',
                    message: chunkUpload.message || '分片上传失败'
                  })
                  isStop = true
                  reject(chunkUpload)
                }
              }
              if (counter === len - 1) {
                // 最后一个上传完成
                resolve()
              } else {
                counter++
                // 更新进度条
                percentage[counter] = chunkSize
                updatePercentage(e)
                start()
              }
            }
          }

          while (limit > 0) {
            setTimeout(() => {
              start()
            }, Math.random() * 1000)
            limit -= 1
          }
        })
      }

      // 【具体和后端交互的业务】上传流程：1）注册 2）提交分片 3）合并
      try {
        // 先注册/获取上传文件信息
        const registerData = {
          file_name: option.file.name, // 文件名-合并块时使用
          file_md5: md5, // 文件MD5值，防止篡改
          file_size: optionFile.size, // 文件总大小
          chunk_size: chunkSize, // 每个块的大小
          total_chunks: fileChunkedList.length // 块的总数量
        }

        // 给后端发送文件合并请求
        let registerRes = await fileChunkRegister(registerData)
        // console.log('registerRes', registerRes)
        let registerResData = registerRes.data

        let Finish = registerResData.finish || 0
        // 断点续传设置续传块编号和偏移值
        // eslint-disable-next-line no-console
        // console.log('断点续传设置续传块编号和偏移值')
        chunkContinuesNum = registerResData.chunk_num
        chunkOffset = registerResData.chunk_offset

        if (Finish) {
          Message({
            type: 'success',
            message: '已经上传，不需重复上传！'
          })
          option.onSuccess(registerResData)
          return
        }

        // 调用上传队列方法 等待所有文件上传完成
        await sendRequest(fileChunkedList, 1)

        // 这里的参数根据自己实际情况写
        const data = {
          file_md5: md5, // 文件MD5值，防止篡改
          chunk_size: chunkSize, // 每个块的大小
          total_chunks: registerData.total_chunks // 块的总数量
        }
        // 给后端发送文件合并请求
        const fileInfo = await fileChunkMerge(data)
        if (fileInfo.code !== 0) {
          Message({
            type: 'warning',
            message: fileInfo.message || '文件合并失败'
          })
          return
        }

        option.onSuccess(fileInfo.data)
      } catch (error) {
        option.onError(error)
      }
    }
  }

  fileReader.onerror = function(e) {
    console.error('文件读取出错:', e.target.error)
  }

  //装载分片：计算整个文件的MD5值
  // console.time('loadNext')

  loadNext()

  Message({
    type: 'info',
    message: '文件解析中'
  })
}
