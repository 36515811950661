<template>
  <div>
    <page-header-layout>
      <div class='main-page-content'>
        <div class='flex-sb'>
          <h2>平台订单导入及预览</h2>
          <el-link type='primary' icon='el-icon-arrow-left' @click='goBack'>返回</el-link>
        </div>
        <div>
          <ImportAndCreate ref='refImportAndCreate'></ImportAndCreate>
        </div>

        <div>
          <h2>导入记录</h2>
          <div>
            <el-form inline :model='searchCondition'>
              <el-form-item label='文件名'>
                <el-input v-model='searchCondition.name' placeholder='上传文件名' clearable />
              </el-form-item>
              <el-form-item label='文件Md5值'>
                <el-input v-model='searchCondition.md5' placeholder='文件Md5值' clearable></el-input>
              </el-form-item>
              <el-form-item label='是否上传完成'>
                <el-select v-model='searchCondition.finish'>
                  <el-option :value='1' label='上传完毕'></el-option>
                  <el-option :value='0' label='上传中'></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button type='primary' @click.native.stop='handleQuery'>查询</el-button>
              </el-form-item>
              <el-form-item>
                <el-button :disabled='multiple' type='danger' @click.native.stop='handleRemoveSelection'>删除</el-button>
              </el-form-item>
              <el-form-item>
                <el-button :disabled='!single' @click.native.stop='showExcelData'>预览订单数据</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div>
            <el-table :data='dataList' @row-dblclick='handleRowEvent' @selection-change='handleSelectionChange'>
              <el-table-column type='selection'></el-table-column>
              <el-table-column label='序号' type='index'></el-table-column>
              <el-table-column label='文件名' prop='name'></el-table-column>
              <el-table-column label='文件大小' prop='total_size'>
                <template slot-scope='{row}'>
                  {{ $utils.byteToSize(row.total_size) }}
                </template>
              </el-table-column>
              <el-table-column label='是否上传完毕' prop='finish'>
                <template slot-scope='{row}'>
                  <span>{{ row.finish == 1 ? '上传完毕' : '上传中' }}</span>
                </template>
              </el-table-column>
              <el-table-column label='上传时间' prop='created_at'></el-table-column>
              <el-table-column label='上传进度'>
                <template slot-scope='{row}'>
                  <span>{{ row.chunk_num }}/{{ row.chunk_count }}</span>
                </template>
              </el-table-column>
              <el-table-column label='文件md5值' prop='md5'></el-table-column>
              <el-table-column label='操作' width='100'>
                <template>
                  <el-link type='primary' icon='el-icon-data-analysis' @click='handleRemove'></el-link>
                  &nbsp;
                  &nbsp;
                  &nbsp;
                  &nbsp;
                  <el-link type='danger' icon='el-icon-close' @click='handleRemove'></el-link>
                </template>
              </el-table-column>
            </el-table>
            <div>
              <m-pagination v-show='total>0' :total.sync='total' :limit.sync='pagingData.page_size'
                            :page.sync='pagingData.current_page'
                            @pagination='getList'></m-pagination>
            </div>
          </div>

          <div style='border-top: #f2f2f2 1px dashed;padding-top: 10px;margin-top: 10px'>
            <excel-page-table :key='`ExcelTable_${currentRow.id}`' :file-info='currentRow'
                              ref='refExcelPageTable'></excel-page-table>
          </div>
        </div>
      </div>
    </page-header-layout>
  </div>
</template>

<script>
import FileChunkUpload from '@/components/import/chunk/FileChunkUpload'
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import { fileUploadBatchDelete, fileUploadList } from '@/api/fileUpload'
import ExcelPageTable from '@/pages/trade/import/ExcelPageTable'
import PlatformSelect from '@/pages/lb/components/PlatformSelect'
import PlatformSingleSelect from '@/components/Platform/PlatformSingleSelect'
import ImportAndCreate from '@/pages/trade/import/ImportAndCreate'

export default {
  name: 'TradeImport',
  components: {
    ImportAndCreate,
    PlatformSingleSelect,
    PlatformSelect,
    ExcelPageTable,
    PageHeaderLayout,
    FileChunkUpload
  },
  data() {
    return {
      dataList: [],
      searchCondition: {},
      pagingData: { page_size: 10, current_page: 1 },
      excelPage: { page_size: 10, current_page: 1 },
      total: 0,
      currentRow: {},
      ids: [],
      single: false,
      multiple: false,
      excelList: [],
      loadingCommit: false,
      formData: { month: null, file_id: null }
    }
  },
  created() {
    this.handleQuery()
  },
  methods: {
    handleQuery() {
      this.pagingData.current_page = 1
      this.getList()
    },
    async getList() {
      let param = { ...this.searchCondition }
      Object.assign(param, this.pagingData)
      let { list, page_info } = await fileUploadList(param)
      this.dataList = list || []
      this.pagingData.current_page = page_info.current_page || 1
      this.pagingData.page_size = page_info.page_size || 10
      this.total = page_info.total || 0
    },
    handleRemove(row, index) {
      this.$confirm('确定删除文件', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await this.delRow(row.id)
        this.dataList.splice(index, 1)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    async delRow(id) {
      const index = this.dataList.findIndex(value => value.id === id)
      this.dataList.splice(index, 1)
      await fileUploadBatchDelete(id)
      return id
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.multiple = !selection.length
      this.single = selection.length === 1
      if (this.single) {
        this.currentRow = selection[0] || {}
      }
    },
    async handleRemoveSelection() {
      const proms = []
      this.ids.forEach((id) => {
        proms.push(this.delRow(id))
      })
      // 批量删除
      await Promise.all(proms)
    },
    //行双击事件
    // eslint-disable-next-line no-unused-vars
    handleRowEvent(row, column, event) {
      //阻止事件冒泡
      // console.log(event)
      this.handleShow(row)
    },
    handleShow(row) {
      this.currentRow = row
      this.showExcelData()
    },
    /**
     * 选中文件装载到《订单表》
     * @returns {Promise<void>}
     */
    async showExcelData() {
      this.$nextTick(() => {
        this.$refs['refExcelPageTable'].init()
      })
    },
    goBack() {
      this.$router.push({ name: 'trade' })
      // this.$router.go(-1)
    }
  }
}
</script>

<style scoped>
.import-box {
  border: #f2f2f2 1px dashed;
  border-radius: 10px;
  /*text-align: center;*/
  padding: 20px;
  margin: auto;
  width: 500px;
}
</style>
