<template>
  <el-select v-bind='$attrs' v-on='$listeners'
             style='width: 100%'
             clearable>
    <el-option v-for='(item,key) in platforms'
               :label='item.name' :value='item.code'
               :key='key'>
      {{ item.name }}
    </el-option>
  </el-select>
</template>

<script>
export default {
  name: 'PlatformSingleSelect',
  data() {
    return {
      platforms: []
    }
  },
  async created() {
    await this.getPlatforms()
  },
  methods: {
    reset() {
      this.$emit('update:value', null)
    },
    getSelectedNode(val) {
      return this.platforms.find(value => value.code === val)
    },
    async getPlatforms() {
      // 获取最新平台信息
      let searchCondition = this.handleSearchCondition()
      let { list } = await this.$api.getPlatformAll(searchCondition)
      this.platforms = list || []

    },
    // 处理搜索条件
    handleSearchCondition() {
      let condition = {}
      condition.status = 1
      condition.is_data_entry = 1

      return condition
    }
  }
}
</script>

<style scoped>

</style>
