<template>
  <div>
    <el-upload
      :http-request='chunkedUpload'
      :ref='chunkedUploadRef'
      :action='uploadUrl'
      :data='uploadData'
      :on-error='onError'
      :on-success='onSuccess'
      :before-remove='beforeRemove'
      drag
      name='file'>
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
<!--      <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>-->
<!--      <el-button size='small' type='primary'>{{ actionTitle }}</el-button>-->
    </el-upload>
  </div>
</template>

<script>
import chunkedUpload from './chunkedUpload'
import { fileChunkDelete } from '@/api/fileUpload'

export default {
  name: 'FileChunkUpload',
  props: {
    chunkedUploadRef: {
      type: String
    }
  },
  data() {
    return {
      actionTitle: '点击上传',//解析中、上传中、上传完毕
      uploadData: {
        //这里面放额外携带的参数
      },
      //文件上传的路径
      uploadUrl: '/api/fileUpload/chunk/upload', //文件上传的路径
      chunkedUpload: chunkedUpload // 分片上传自定义方法，在头部引入了
    }
  }, methods: {
    // eslint-disable-next-line no-unused-vars
    onError(err, file, fileList) {
      // eslint-disable-next-line no-console

      this.$alert('文件上传失败，请重试', '错误', {
        confirmButtonText: '确定'
      })
    },
    async beforeRemove(file) {
      // 调用删除接口
      const data = {
        fileName: file.name, // 文件名
        fileSize: file.size // 文件大小
      }
      await fileChunkDelete(data)
    },
    onSuccess(fileInfo){
      this.$emit('uploaded',fileInfo)
    }
  }
}
</script>

<style scoped>

</style>
