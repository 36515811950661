import * as request from '@/utils/request'

//文件分片注册
export function fileChunkRegister(data) {
  return request.post('/fileUpload/chunk/register', data)
}

//文件分片上传
export function fileChunkUpload(data) {
  return request.post('/fileUpload/chunk/upload', data)
}

//文件碎片合并
export function fileChunkMerge(data) {
  return request.post('/fileUpload/chunk/merge', data)
}

//文件分片删除
export function fileChunkDelete(data) {
  return request.post('/fileUpload/chunk/delete', data)
}


//【文件分片上传】记录列表
export function fileUploadList(data) {
  return request.post('/fileUpload/chunk/list', data)
}

//【文件分片上传】记录删除
/**
 * ids 若是数组，则批量删除、若是数值，则单个删除
 * ids: 1
 * ids:[1,2,3]
 * @param ids
 * @returns {Promise}
 */
export function fileUploadBatchDelete(ids) {

  return request.post('/fileUpload/chunk/batch_delete', { id:ids })
}
