import * as request from '@/utils/request'

/**
 *
 * {file_upload_id:1,page_size:10,current_page:1}
 * @param  {*} params
 * @returns {Promise}
 */
export function readTradeFile(params) {
  return request.post('/trade_import/read', params)
}

/**
 *
 * 下载平台数据导入模板【平台必传】
 * @param {platform_code,platform_name} data
 * @returns {Promise}
 */
export function exportTradeImportedTpl(data) {

  const t = (new Date()).getTime()

  return request.postBlob(`/trade_import/export_tpl?t=${t}`, {
    platform_code: data.platform_code,
    platform_name: data.platform_name
  })
}

/**
 *
 * 保存已导入的文件内容到平台订单数据表
 * @param data
 * @returns {Promise}
 */
export function saveTradeImported(data) {
  return request.post('/trade_import/save', data)
}

/**
 *
 * 已上传的表格载入到数据库
 * @param id
 * @returns {Promise}
 */
export function storeTradeImported(id) {
  return request.post('/trade_import/store', { id: id })
}

/**
 * 匹配红人
 * @param id
 * @returns {Promise}
 */
export function matchTradeImported(id) {
  return request.post('/trade_import/match', { id: id })
}

/**
 *
 * 撤回已导入的文件
 * @param id
 * @returns {Promise}
 */
export function cancelTradeImported(id) {
  return request.post('/trade_import/cancel', { id: id })
}

/**
 * 删除记录
 * @param id
 * @returns {Promise}
 */
export function deleteTradeImported(id) {
  return request.post('/trade_import/delete', { id: id })
}

/**
 * 可选项
 * @returns {Promise}
 */
export function optionsTradeImported() {
  return request.post('/trade_import/options', {})
}

/**
 * 导入记录查询
 * @param params
 * @returns {Promise}
 */
export function getTradeImportList(params) {
  return request.post('/trade_import/list', params)
}
